import React from "react";
import { useStateValue } from "../StateProvider";
import UseGoogleSearch from "../UseGoogleSearch";
import "./SearchPage.css";
import response from "../response";
import { Link } from "react-router-dom";
import Search from "../components/Search";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MapIcon from "@mui/icons-material/Map";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const SearchPage = () => {
  const [{ term }, dispatch] = useStateValue();

  //LIVE API CALL
  const { data } = UseGoogleSearch(term);
//MOCK API CALL
//   const data = response;
  console.log(data);
  return (
    <div className="searchpage">
      <div className="searchpage__header">
        <Link to="/">
          <img
            className="searchpage__logo"
            src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png"
            alt=""
          />
        </Link>

        <div className="searchPage__header-body">
          <Search hideButtons />
          <div className="searchpage__options">
            <div className="options__left">
              <div className="searchpage__option">
                <SearchIcon />
                <Link to="/all">All</Link>
              </div>
              <div className="searchpage__option">
                <DescriptionIcon />
                <Link to="/news">News</Link>
              </div>
              <div className="searchpage__option">
                <ImageIcon />
                <Link to="/images">Images</Link>
              </div>
              <div className="searchpage__option">
                <LocalOfferIcon />
                <Link to="/shopping">Shopping</Link>
              </div>
              <div className="searchpage__option">
                <MapIcon />
                <Link to="/maps">Maps</Link>
              </div>
              <div className="searchpage__option">
                <MoreVertIcon />
                <Link to="/more">more</Link>
              </div>
            </div>
            <div className="options__right">
              <div className="searchpage__option">
                <Link to="/settings">Settings</Link>
              </div>
              <div className="searchpage__option">
                <Link to="/tools">Tools</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {term && (
        <div className="searchpage__results">
          <p className="searchpage__result--count">
            About {data?.searchInformation.formattedTotalResults} results (
            {data?.searchInformation.formattedSearchTime} Seconds) for {term}
          </p>

          {data?.items.map(item =>  ( 
            <div key={item.cacheID} className="searchpage__result">
                <a className="searchpage__result--link" href={item.link}>
                    {item.pagemap?.cse_image?.length > 0 && item.pagemap?.cse_image[0]?.src && (
                        <img className="searchpage__result--img" src={item.pagemap?.cse_image[0]?.src}>
                            
                        </img>
                    )}
                {item.displayLink} ▼
                </a>
                <a className="searchpage__result--title" href={item.link}>
                    <h2>{item.title}</h2>
                </a>
                <p className="searchpage__result--snippet">
                    {item.snippet}
                </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchPage;
